import './TourIntro.css'
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import frame from '../../assets/Design Frames/08_NextStop.jpg'
import { useLocation, } from 'react-router-dom';
import GlobalButton from '../global/GlobalButton';
import { redirect, useNavigate } from 'react-router';
import { setCurrentHotspotID, setCurrentTourUrl, setIsScanning, setIsTourLoaded, setIsTourLoading, setIsTracking, setTourID } from '../../reducers/ToursReducer';
import { setShowMenu } from '../../reducers/AppStateReducer';

function TourIntro(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const tourData = useSelector((state) => state.tours)
    const appState = useSelector((state) => state.appState)

    useEffect(() => {

        if(tourData.currentTourID != props.tourID){
            dispatch(setTourID(props.tourID))
        }

        if(props.redirectIntroToAr){
            navigate(props.redirectLink)
            return;
        }

        
        dispatch(setCurrentTourUrl(null));
        dispatch(setIsTourLoaded(false))
        dispatch(setIsTourLoading(false))
        dispatch(setIsTracking(false))
        dispatch(setIsScanning(false))
        dispatch(setShowMenu(false))
    },[location])


    if(!tourData || !tourData.currentTour){
        return <></>
    }

        return (
            <React.Fragment>
                <div className="intro-wrapper">
                    <div className='intro-title'>
                        <span className="blockout-style " dangerouslySetInnerHTML={{__html:tourData.currentTour.tourTitle}}></span>
                    </div>
                    <div className='intro-scroll-container'>
                        <img className="intro-image" src={tourData.currentTour.tourImage}/>
                        <img className="qr-code" src='/AR/images/QR code stickers_Phase 2_Watts_05.png'/>
                    
                        <p className="paragraph-style" dangerouslySetInnerHTML={{__html:tourData.currentTour.introDescription}}></p>
                        <span className="intro-spacer"/>
                        
                    </div>
                </div>
            </React.Fragment>
        );
    
}

export default TourIntro