
import './App.css';
import React, {useEffect, useState} from 'react'; 
import ReactGA from 'react-ga4';
import { render } from 'react-dom';
import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  redirect
} from 'react-router-dom';

import { createStore } from 'redux';
import rootReducer from './reducers/RootReducer'
import {setStopId, setData, setIsPublicSite } from './reducers/ToursReducer';
import DevPage from './routes/DevPage';
import TourIntro from './components/TourIntro/TourIntro';
import Home from './routes/Home/Home';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ARWrapper from './routes/AR/ARWrapper';
import { isMobile } from 'react-device-detect';
import useScreenOrientation from './ScreenOrientation';
import frame from './assets/Design Frames/09_stop-intro.jpg'
import IFrameContainer from './routes/AR/IFrameContainer';
import Page from './components/Page/Page';
import Tour from './components/Tour/Tour';
import Map from './components/Map/Map';
import ToursMenu from './routes/ToursMenu/ToursMenu';
import { useDispatch, useSelector, Provider } from 'react-redux';
import * as contentful from 'contentful';

let client;
const urlPrefix = "https:"

async function getEntry(entryID){
    const entry = await client.getEntry(entryID)
    return entry
}

function getAllTours(allEntries){
    return allEntries.items.filter((i) => i.fields.tourID)
    
}

function getConfig(allEntries){
    return allEntries.items.find((i) => i.fields.deafultHeader).fields
}

function convertRichText(richText){
    let __html = "";
    richText.content.map((content, i, arr) => {
        content.content.map((textBlock) => {
            if(textBlock.marks.length > 0){
                textBlock.marks.map((mark) => {
                    if(mark.type == 'italic') __html+= "<i>";
                    if(mark.type == 'bold') __html+= "<b>";
                })
            }
            __html += textBlock.value;
            if(textBlock.marks.length > 0){
                textBlock.marks.map((mark) => {
                    if(mark.type == 'italic') __html+= "</i>";
                    if(mark.type == 'bold') __html+= "</b>";
                })
            }
        })
        if(i < arr.length -1)
        __html += "<br/><br/>"
    })
    return __html;
}


export async function getCMSData(isPublicSite) {

    let data = {'tours':[], 'config':{}};
    if(!client)
        client = contentful.createClient({
            space: 'tfnidb7j7d87',
            accessToken: '28UYW_QAaH_Lcxn9NYgzqa6fGdv1ytgtzwABK0QkXsM',
            environment: isPublicSite ? 'master' : 'Dev-2023-12-20'
        })

    const allEntries = await client.getEntries()
    //console.log("[ContentfulWrapper] All Entries:", allEntries)

    //GET ALL TOURS
    let cmsTours = getAllTours(allEntries)
    cmsTours.map((e) => {
        data.tours.push(e.fields)
    })

    //GET CONFIG
    let cmsConfig = getConfig(allEntries);
    data.config = cmsConfig;

    data.config.desktopErrorMsg = convertRichText(data.config.desktopErrorMsg)
    data.config.landscapeErrorMsg = convertRichText(data.config.landscapeErrorMsg)
    data.config.mapImage = urlPrefix + data.config.mapImage.fields.file.url

    //REMOVE "FIELDS" IN STOPS and HOTSPOTS
    //Replace images with direct urls

    //[TOURS]
    data.tours.forEach((tour,t_index) => {
        data.tours[t_index].tourImage = urlPrefix + tour.tourImage.fields.file.url
        data.tours[t_index].introDescription = convertRichText(tour.introDescription)

        //[STOPS]
        tour.stops.forEach((stop, s_index) => {
            data.tours[t_index].stops[s_index] = stop.fields
            data.tours[t_index].stops[s_index].imageTargetExample = urlPrefix + stop.fields.imageTargetExample.fields.file.url

            //[HOTSPOTS]
            data.tours[t_index].stops[s_index].hotspots.forEach((hotspot, h_index) => {
                data.tours[t_index].stops[s_index].hotspots[h_index] = hotspot.fields

                //[HOTSPOT MEDIA]
                if(data.tours[t_index].stops[s_index].hotspots[h_index].media){
                    data.tours[t_index].stops[s_index].hotspots[h_index].media.forEach((media, m_index) => {
                        if(media.fields.image){
                            if(!data.tours[t_index].stops[s_index].hotspots[h_index].images)
                                data.tours[t_index].stops[s_index].hotspots[h_index].images = [] 

                            media.fields.image = urlPrefix + media.fields.image.fields.file.url;
                            media.fields.pinchZoomImage = urlPrefix + media.fields.pinchZoomImage.fields.file.url;
                            if(media.fields.captionBodyText)
                                media.fields.captionBodyText = convertRichText(media.fields.captionBodyText)
                            data.tours[t_index].stops[s_index].hotspots[h_index].images.push(media.fields)

                        }
                        else if (media.fields.video){
                                data.tours[t_index].stops[s_index].hotspots[h_index].images = [] //if video, only take video
                                data.tours[t_index].stops[s_index].hotspots[h_index].video = media.fields.video.fields.file.url
                            
                            
                        }
                    })
                }

                //[HOTSPOT BODY TEXT]
                data.tours[t_index].stops[s_index].hotspots[h_index].bodyText = 
                    hotspot.fields.bodyText.content[0].content[0].value

            })
            
        })
    })

    return data;
}