import React, { useEffect, useState, useRef } from 'react';
import './Page.css'
import ArrowIcon from './icons/left_arrow.png'
import ArrowIconRight from './icons/right_arrow.png'
import { connect, useDispatch, useSelector } from 'react-redux';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { Button } from '@mui/material';
import PinchZoom from '../PinchZoom/PinchZoom';
import { setHotspotID, setIsBackDisabled, setStopId } from '../../reducers/ToursReducer';
import ReactPlayer from 'react-player'
import { useLoaderData, useNavigate, redirect, Outlet } from 'react-router';
import { TrackGAEvent } from '../Analytics/Analytics';
import { useLocation, } from 'react-router-dom';

function Page(props){
    const [hasPinchStarted, setHasPinchStarted] = useState(false)
    
    const [isPinchZoomOpen, setIsPinchZoomOpen] = useState(false)
    const [paginationIndex, setPaginationIndex] = useState(0)
    const tourData = useSelector((state) => state.tours)
    const containerRef = useRef(null);


    const idFromURL = useLoaderData();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    

      useEffect( () => {
       if(tourData.currentHotspot)
        onHotspotSelected(tourData.currentHotspot)
      },[tourData.currentHotspot])

    useEffect(() => {
        if(!tourData.currentStop){
            //console.log("[Page] SETTING STOP", idFromURL);
            dispatch(setStopId(idFromURL.stopId));
        }

        if(!tourData.currentHotspot){
            //console.log("[Page] SETTING HOTSPOT", idFromURL)
            onHotspotSelected(tourData.currentHotspot)
            dispatch(setHotspotID(idFromURL.hotspotID));
        }
        
    }, [idFromURL])
    
    function onHotspotSelected(hotspot){
        if(!hotspot) return;
        TrackGAEvent("custom_event", "Open_Hotspot", hotspot.introText)
        setTimeout(() => {
            const container = containerRef.current; //document.getElementById('container-2d')
            if(container == null)
                return;

            container.style.opacity = 1
            setTimeout(() => {
                container.style.pointerEvents = 'all'
            }, 500)


            const closeButton = document.getElementById('global-nav')
            closeButton.style.opacity = 1
            closeButton.style.pointerEvents = 'all'
        }, 1000)
    }
    
    function handlePinchZoomClose(){
        setIsPinchZoomOpen(false);
    }

    function handleHotspotClose(){
        const container = containerRef.current; //document.getElementById('container-2d')
        container.style.opacity = 0
        container.style.pointerEvents = 'none'
        const closeButton = document.getElementById('global-nav')
        closeButton.style.opacity = 0
        closeButton.style.pointerEvents = 'none'
        dispatch(setHotspotID(null));
        navigate('/ar/' + tourData.currentTour.tourID[0] + '/tour/' + tourData.currentStop.hrefAR)
    }

    function handleNextHotspot(index){
        dispatch(setHotspotID(tourData.currentStop.hotspots[index].hotspotId))
        navigate('/ar/' + tourData.currentTour.tourID[0] + '/tour/' + tourData.currentStop.hrefAR +  '/hotspot/' + tourData.currentStop.hotspots[index].hotspotId)
    }

    function handleImageSelected(){
        TrackGAEvent("custom_event", "Enlarge_Photo", tourData.currentStop.hotspots[tourData.currentHotspotIndex].images[paginationIndex].adminTitle)
        setIsPinchZoomOpen(true)
    }

    function handlePinchStart(){
        TrackGAEvent("custom_event", "Pinch_Zoom", tourData.currentStop.hotspots[tourData.currentHotspotIndex].images[paginationIndex].adminTitle)
    }

    function handleLinkPressed(link){
        TrackGAEvent("custom_event", "Learn_More", link)
    }

    function getMedia(){
        let media = null;
        if(tourData.currentStop.hotspots[tourData.currentHotspotIndex]?.video){
            media = <div className='video-container'>
                        <ReactPlayer url={tourData.currentStop.hotspots[tourData.currentHotspotIndex].video}
                            muted={true} controls={true} width='100%' height='auto' />
                    </div>
        }
        else if(tourData.currentStop.hotspots[tourData.currentHotspotIndex]?.images?.length > 0){
            media = <ImageCarousel 
                        onPaginationIndexChange={(i) => setPaginationIndex(i-1)} 
                        onHotspotImageSelected={() => {handleImageSelected()}} 
                        hotspot={tourData.currentStop.hotspots[tourData.currentHotspotIndex]}/> 
        }
        return media;
    }

    if(!tourData || !tourData.currentStop || !tourData.currentStop.hotspots[tourData.currentHotspotIndex]) return null;
   //TODO: Add learn more link to contentful
    //console.log("hotspot render", tourData.currentStop.hotspots[tourData.currentHotspotIndex])

    return(
    <div ref={containerRef} id='container-2d'>
        {getMedia()}
        <div id='text-container'>
            <h1 id='hotspot-title-text' className='title-text'>{tourData.currentStop.hotspots[tourData.currentHotspotIndex].introText}</h1>
            <span id='hotspot-body-text' className='body-text' dangerouslySetInnerHTML={{__html:tourData.currentStop.hotspots[tourData.currentHotspotIndex].bodyText}}/>
            {
                tourData.currentStop.hotspots[tourData.currentHotspotIndex].websiteText && tourData.currentStop.hotspots[tourData.currentHotspotIndex].websiteText !== '' && tourData.currentStop.hotspots[tourData.currentHotspotIndex].websiteURL && tourData.currentStop.hotspots[tourData.currentHotspotIndex].websiteURL !== '' ? 
                    <a target="_blank" href={tourData.currentStop.hotspots[tourData.currentHotspotIndex].websiteURL} onClick={() => {handleLinkPressed(tourData.currentStop.hotspots[tourData.currentHotspotIndex].websiteURL)}}>{tourData.currentStop.hotspots[tourData.currentHotspotIndex].websiteText}</a>
                :null
            }
        </div>
        <div id="global-nav">
            {tourData.currentStop.hotspots.length > 1 && tourData.currentHotspotIndex > 0 ?
                <Button className="previous-button" variant="text" startIcon={<img src={ArrowIcon} />}
                    onClick={() => {handleNextHotspot(tourData.currentHotspotIndex-1)}}>
                        {"Learn about " + tourData.currentStop.hotspots[tourData.currentHotspotIndex - 1].introText}
                    </Button>:null}  
            
            {tourData.currentStop.hotspots.length > 1 && tourData.currentHotspotIndex < tourData.currentStop.hotspots.length-1 ?
                <Button className="next-button" variant="text" endIcon={<img src={ArrowIconRight} />}
                onClick={() => {handleNextHotspot(tourData.currentHotspotIndex+1)}}>
                    {"Learn about " + tourData.currentStop.hotspots[tourData.currentHotspotIndex + 1].introText}
                </Button>:null}
            <div id='close-button-container'>
                <Button className="button" variant="contained" onClick={handleHotspotClose}>Close</Button>
            </div> 
        </div>
        {isPinchZoomOpen ? <PinchZoom onPinchStart={handlePinchStart} onClose={handlePinchZoomClose} image={tourData.currentStop.hotspots[tourData.currentHotspotIndex].images[paginationIndex]}/> :null}
                
    </div>

    );
    
}



export default Page 